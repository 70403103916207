<template>
    <v-container grid-list-xl fluid v-if="!$store.getters.isLoading">
        <h1>Sessões ativas</h1>
        <v-divider class="mb-4" />
        <v-alert outlined class="mt-12" color="red" prominent border="top" icon="info" v-if="items.length == 0"> Você não possui nenhuma sessão ativa. </v-alert>
        <v-list subheader v-else>
            <v-subheader>Sessões ativas de carro elétrico</v-subheader>
            <v-list-item v-for="(item, i) in items" :key="i" class="elevation-2" color="primary">
                <v-list-item-avatar>
                    <v-icon color="primary"> {{ iconStatus(item.status) }} </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Carregador: {{ item.charger.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <!-- <v-list-item-action-text>Acompanhar</v-list-item-action-text> -->
                    <router-link :to="{ path: nowPath(item.charger.name, item.plug) }" tag="v-btn">
                        <v-btn x-small color="primary">
                            <v-icon left>remove_red_eye</v-icon>
                            Acompanhar
                        </v-btn>
                    </router-link>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-container>
</template>

<script>
export default {
    mounted() {
        this.timer = setInterval(this.update, 5000);
        this.update();
    },

    data() {
        return {
            timer: null,
            items: [],
        };
    },

    methods: {
        update() {
            this.$http
                .get("api/v2/session/" + this.$store.state.user.ID, { continuous: true })
                .then((result) => {
                    if (result) {
                        this.items = result;
                    }
                })
                .catch(() => {
                    this.msg = null;
                });
        },
    },

    computed: {
        iconStatus() {
            return (status) => (status == "Charging" ? "battery_charging_full" : "power");
        },
        nowPath() {
            return (name, plug) => `now/${name}/${plug}`;
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>